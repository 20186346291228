<template>
  <div class="scanner">
    <div class="header-box">
      <h1>{{ $t('scan.title') }}</h1>
    </div>
    <!-- <QrcodeStream class="camera" @decode="onDecode" /> -->
    <QRCodeScanner v-if="!$store.state.congratsAsCamera" class="camera" @result="onDecode" />
    <div v-if="invalidQRCode" class="invalid">
      <span>{{ $t('scan.invalid') }}</span>
    </div>
    <CongratsModal v-if="$store.state.congratsAsCamera" @close="$router.go(-1)" />
  </div>
</template>

<script>
import CongratsModal from '@/components/CongratsModal.vue';
import QRCodeScanner from '@/components/QRCodeScanner.vue';
// import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: 'Scanner',
  components: {
    CongratsModal,
    // QrcodeStream,
    QRCodeScanner,
  },
  data() {
    return {
      result: '',
      invalidQRCode: false,
      constraints: {
        video: {
          facingMode: { exact: 'environment' },
        },
      },
    };
  },
  methods: {
    onDecode(result) {
      // since the qrCode generate accepts a valid url instead of just an endpoint, i am storing the url in an array
      // and then splitting it and joining based on the intended functionality of the url
      const myArray = result.split('/');
      if (myArray[2] !== process.env.VUE_APP_DOMAIN) {
        this.invalidQRCode = true;
      } else {
        this.invalidQRCode = false;
        this.$router.push({ name: 'target', params: { id: myArray[4] } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-box {
  h1 {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 48px;
  }
}
.camera {
  width: 275px !important;
  height: 275px !important;
}
.invalid {
  margin-top: 1em;
  font-size: 18px;
  font-weight: bold;
  color: $red;
  text-transform: uppercase;
}
.logo {
  display: block;
  width: 2.5em;
  height: 1.2em;
  margin-top: 2em;
  margin-right: auto;
  margin-bottom: 1em;
  margin-left: auto;
}
</style>
