<template>
  <div class="modal-wrap">
    <div class="modal">
      <div class="modal-body">
        <XIcon class="x-icon" @click="$emit('close')" />
        <video class="video-frame" poster="@/assets/ford/13_Congrats-thumbnail.png" autoplay muted loop playsinline>
          <source src="@/assets/ford/13_Congrats-b444-hevc-safari.mp4" type='video/mp4; codecs="hvc1"' />
          <source src="@/assets/ford/13_Congrats-b444-vp9-chrome.webm" type="video/webm" />
        </video>
        <h1>{{ $t('scan.congrats.title') }}</h1>
        <p>{{ $t('scan.congrats.caption') }}</p>
        <template v-if="triviaUUID">
          <h2>{{ $t('scan.trivia.title') }}</h2>
          <p>{{ $t('scan.trivia.caption') }}</p>
          <PassportButton :to="`/target/${triviaUUID}`" :label="$t('scan.trivia.button')" class="button" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import XIcon from '@/assets/icons/x_icon.svg';
import PassportButton from '@/components/PassportButton.vue';

export default {
  components: {
    PassportButton,
    XIcon,
  },
  computed: {
    triviaUUID() {
      return process.env.VUE_APP_TRIVIA_UUID;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  min-height: calc(100vh - 73px);
  background: rgba(36, 58, 83, 0.8);
  backdrop-filter: blur(5px);
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    max-width: 291px;
    max-height: 90%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    // max-height: 479.406px;
    // .modal-content {
    //   overflow-y: scroll;
    // }
    .modal-body {
      position: relative;
      padding: 32px;
      color: #fff;
      background-color: #243a53;
      h1 {
        margin-top: 28px;
        margin-bottom: 22px;
      }
      p {
        margin-top: 0;
        margin-bottom: 32px;
      }
      h2 {
        margin-top: 62px;
        margin-bottom: 23px;
      }
      .button {
        // white-space: nowrap;
        max-width: 100%;
        padding: 11px 16px;
        margin: 0 auto;
      }
    }
  }
}
.x-icon {
  position: fixed;
  top: 27px;
  right: 27px;
  width: 20px;
}
.video-frame {
  width: 150px;
}
</style>
