<template>
  <div class="camera-container">
    <video ref="qrCode" id="qr-code-full-region" :class="{ 'is-loading': isLoading }"></video>
    <!-- {{ currentCamera }}
    <select v-model="currentCameraId" @change="setCamera(currentCameraId)">
      <option v-for="camera in cameras" :key="camera.id" :value="camera.id">{{ camera.label }}</option>
    </select> -->
  </div>
</template>

<script>
import QrScanner from 'qr-scanner';

export default {
  name: 'QRCodeScanner',
  props: {
    qrbox: {
      type: Number,
      default: 275,
    },
    fps: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      qrScanner: null,
      currentCamera: null,
      currentCameraId: 'environment',
      cameras: [{ id: 'environment', label: 'Default' }],
      isLoading: true,
    };
  },
  mounted() {
    this.isLoading = true;
    this.qrScanner = new QrScanner(
      this.$refs.qrCode,
      (result) => {
        if (result) {
          // console.log(result.data);
          // this.$refs.qrCode.pause();
          // this.qrScanner.stop();
          /* disable QrScanner */
          // this.qrScanner._active = !1;
          this.$emit('result', result.data);
          this.$nextTick(() => {
            // this.qrScanner.start();
          });
        }
      },
      {
        onDecodeError: () => {
          // console.log(error);
        },
        // maxScansPerSecond: 6,
        /* your options or returnDetailedScanResult: true if you're not specifying any other options */
      },
    );
    this.qrScanner.start().then(() => {
      // updateFlashAvailability();
      // List cameras after the scanner started to avoid listCamera's stream and the scanner's stream being requested
      // at the same time which can result in listCamera's unconstrained stream also being offered to the scanner.
      // Note that we can also start the scanner after listCameras, we just have it this way around in the demo to
      // start the scanner earlier.
      QrScanner.listCameras().then((cameras) => {
        this.cameras.push(...cameras);
        const backCamera = cameras.find(
          (camera) =>
            camera.label.includes('Back Camera') ||
            camera.label.includes('back camera') ||
            camera.label.includes('facing back') ||
            camera.label.includes('Facing back'),
        );
        if (backCamera) {
          this.qrScanner.setCamera(backCamera.id).then(() => {
            // eslint-disable-next-line no-console
            console.log('set camera done');
            this.currentCameraId = backCamera.id;
            this.$nextTick(() => {
              setTimeout(() => {
                this.isLoading = false;
              }, 1000);
              // this.isLoading = false;
            });
          });
        } else {
          // eslint-disable-next-line no-console
          console.log('no back camera found');
          this.$nextTick(() => {
            this.isLoading = false;
          });
        }
        /* this.currentCamera = backCamera;
        this.qrScanner.setCamera(backCamera.id).then(() => {
          // console.log('set camera done');
        }); */
      });
    });
  },
  unmounted() {
    this.qrScanner.destroy();
    this.qrScanner = null;
  },
  methods: {
    setCamera(cameraId) {
      // eslint-disable-next-line no-console
      console.log(cameraId);
      this.qrScanner.setCamera(cameraId).then(() => {
        // eslint-disable-next-line no-console
        console.log('set camera done');
      });
    },
    onScanSuccess(decodedText, decodedResult) {
      // eslint-disable-next-line no-console
      console.log(decodedText, decodedResult);
      this.$emit('result', decodedText);
    },
  },
};
</script>

<style lang="scss" scoped>
.camera-container {
  position: relative;
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.25s ease-in-out;
    &.is-loading {
      opacity: 0;
    }
  }
}
</style>
